import { login, editPassword } from '../server/user';
export default {
    namespaced: true,
    state: {
        date: ''
    },
    getters: {
        isLogin()
        {
            return () => !!localStorage.getItem('user');
        },
        info(state)
        {
            const str = localStorage.getItem('user');
            const info = JSON.parse(str);
            info.date = state.date;
            return info;
        }
    },
    mutations: {
        updateDate(state)
        {
            state.date = Date.now();
        }
    },
    actions: {
        async login({commit},data)
        {
            const user = await login(data);
            localStorage.setItem('user',JSON.stringify(user));
            commit('updateDate');
        },
        logout()
        {
            localStorage.removeItem('user');
        },
        async editPassword(context,data)
        {
            await editPassword(data);
        }
    }
};