<style scoped>
.tabBarPC
{
    position: fixed;
    width: var(--bar-width);
    height: 100%;
    margin-left: var(--bar-left);
}

.logo
{
    width: 38px;
    height: 38px;
    background-image: url(@/assets/images/logo.png);
    background-size: cover;
    flex-shrink: 0;
}

.logoLabel
{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.items
{
    margin: 11px;
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.items .ant-btn
{
    height: 38px;
    border-radius: 10px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 8px;
    gap: 5px;
    --icon-practice: url(../assets/svg/icons/practice.svg);
    --icon-exam: url(../assets/svg/icons/exam.svg);
    --icon-user: url(../assets/svg/icons/me.svg);
    color: rgba(0, 0, 0, 0.6);
}

.items .ant-btn-primary
{
    --icon-practice: url(../assets/svg/icons/practice1.svg);
    --icon-exam: url(../assets/svg/icons/exam1.svg);
    --icon-user: url(../assets/svg/icons/me1.svg);
    color: black;
}

.items .ant-btn .icon
{
    width: 20px;
    height: 20px;
    background-size: contain;
}

@media screen and (max-width: 1038px)
{
    .items .ant-btn
    {
        padding: 0;
        justify-content: center;
    }

    .items .ant-btn .label
    {
        display: none;
    }
}
</style>
<template>
    <div class="tabBarPC">
        <div style="padding: 10px;display: flex;align-items: center;gap: 10px;font-size: 18px;">
            <div class="logo" />
            <span class="logoLabel">移动智能自主培训平台</span>
        </div>
        <div class="items">
            <a-button :style="{fontWeight: selectedTabIdx === idx && routeName !== 'home' ? 600 : 400}" block size="large" v-for="(tab,idx) of tabs" :key="idx" :ghost="selected(idx)" :type="selected(idx) ? 'primary' : 'text'" @click="selectHandler(idx)">
                <template #icon>
                    <div class="icon" :style="`background-image: var(--${tab.icon})`"></div>
                </template>
                <span class="label">{{tab.label}}</span>
            </a-button>
        </div>
    </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex';
export default {
    computed: {
        ...mapState(['routeName']),
        ...mapState('home',['tabs','selectedTabIdx']),
        selected()
        {
            return idx => {
                return this.selectedTabIdx === idx && this.routeName === 'home';
            };
        }
    },
    methods: {
        ...mapMutations('home',['setSelectedTabIdx']),
        selectHandler(idx)
        {
            if(this.routeName !== 'home')this.$router.back();
            this.setSelectedTabIdx(idx);
        }
    }
}
</script>