import { get, post, put } from "./config";

export const getExamList = async ({timestamp,page,size}) => {
    const url = `/app/exam/calender?timestamp=${timestamp}&page=${page}&size=${size}`;
    const { exams } = await get(url,true);
    return exams;
};

export const getExamResultThisMonth = async () => {
    const url = '/app/exam-result/this-month';
    return await get(url,true);
};

export const editStatus = async ({examResultId}) => {
    const url = `/app/examResultMp/editStatus?examResultId=${examResultId}`;
    return await get(url,true);
};

export const updateOptionById = async data => {
    const url = '/app/examMp/updateOptionById';
    return await put(url,data,true);
};
export const getExamResult = async ({examResultId}) => {
    const url = `/app/examResultMp/getExamResult?examResultId=${examResultId}`;
    return await get(url,true);
};
export const editExamResult = async data => {
    const url = '/app/examResultMp/edit';
    return await post(url,data,true);
};

export const examResultGetTime = async data => {
    const url = '/app/examResultMp/getTime?times='+data.times+'&examResultId='+data.examResultId;
    return await get(url,data,true);
};
export const wrongAnswerMpSave = async data => {
    const url = '/app/wrongAnswerMp/save';
    return await post(url,data,true);
};
export const rightAnswerMpSave = async data => {
    const url = '/app/rightAnswerMp/save';
    return await post(url,data,true);
};
export const startExam = async examId => {
    const url = '/app/exam-result';
    const data = { examId };
    return await post(url,data,true);
};

export const continueExam = async examResultId => {
    const url = '/app/exam-result/continue';
    const data = { examResultId };
    return await put(url,data,true);
};

export const finishExam = async data => {
    const url = '/app/exam-result';
    return await put(url,data,true);
};

export const getExamInfo = async examId => {
    const url = `/app/exam?examId=${examId}`;
    return await get(url,true);
};

export const heartbeat = async examResultId => {
    const url = '/app/exam-result/heartbeat';
    const data = { examResultId };
    return await put(url,data,true);
};