<style>
    @import url(@/assets/iconfont/iconfont.css);
    @import url(@/assets/css/index.css);
    @import url(@/assets/css/pc.css);
</style>

<template>
    <a-config-provider :locale="locale" :theme="theme">
        <template v-if="!$isMobile && routeName && routeName !== 'login'">
            <tab-bar-pc />
            <div class="pcBorder"></div>
        </template>
        <router-view />
    </a-config-provider>
</template>
<script>
  import zhCN from 'ant-design-vue/es/locale/zh_CN';
  import dayjs from 'dayjs';
  import 'dayjs/locale/zh-cn';
  import TabBarPc from './components/TabBarPc';
  import { mapState } from 'vuex';
  import theme from './theme';

  dayjs.locale('zh-cn');

  export default {
    components: { TabBarPc },
    data()
    {
        return {
            locale: zhCN,
            theme
        };
    },
    computed: {
        ...mapState(['routeName'])
    },
    mounted()
    {
        if(!this.$isMobile)
        {
            document.body.classList.add('pc');
        }
    }
  };
</script>