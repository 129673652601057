import router from "@/router";

export const server = {
    root: process.env.VUE_APP_SERVER_ROOT
};

const tokenError = (msg) => {
    localStorage.removeItem('user');
    router.push({path: '/login'});
    throw new Error(msg);
};

export const getJson = async res => {
    const json = await res.json();
   if(typeof variable != 'object'){
    const {code,msg,error} = json;
    if(error)throw new Error(error);
    if(code === 10)return tokenError(msg);
    if(code !== 0)throw new Error(msg);
   }
    return json;
};

export const fetchPlus = async (url,data,options = {}) => {
    const res = await fetch(`${server.root}${url}`,{
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
        ...options
    });
    return await getJson(res);
};

export const getToken = () => {
    const str = localStorage.getItem('user');
    const user = JSON.parse(str);
    return user.token;
};

export const useToken = options => {
    const token = getToken();
    options.headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
};

export const post = async (url,data,withToken = false) => {
    const options = {
        method: 'POST'
    };
    if(withToken)useToken(options);
    return await fetchPlus(url,data,options);
};

export const put = async (url,data,withToken = false) => {
    const options = {
        method: 'PUT'
    };
    if(withToken)useToken(options);
    return await fetchPlus(url,data,options);
};

export const get = async (url,withToken = false) => {
    const options = {
        method: 'GET',
        body: null
    };
    if(withToken)useToken(options);
    return await fetchPlus(url,'',options);
};