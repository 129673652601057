import { getPaperInfo } from '@/server/paper';
import { finishPracticeEveryday, finishPracticeSelf, getPracticeResult } from '../server/practice';

export default {
    namespaced: true,
    state: {
        practiceEveryday: {
            info: '',
            result: '',
            idx: -1,
            answer: {}
        },
        practiceSelf: {
            info: '',
            result: '',
            idx: -1,
            answer: {}
        }
    },
    getters: {
        getFinishPracticeOptions(state)
        {
            return function(key)
            {
                const { result, answer } = state[key];
                const { practiceResultId, paper } = result;
                const qIds = Object.keys(answer);
                const rightAnswers = [];
                const wrongAnswers = [];
                const getQuestion = id => paper.questions.find(item => item.questionId == id);
                for(const qId of qIds)
                {
                    const userAnswer = answer[qId];
                    const question = getQuestion(qId);
                    if(userAnswer === question.rightAnswer)
                    {
                        rightAnswers.push({questionId: qId,answer: userAnswer});
                    }
                    else
                    {
                        wrongAnswers.push({questionId: qId,answer: userAnswer});
                    }
                }
                const rightAnswerQuestionIds = rightAnswers.map(item => item.questionId);
                return {
                    practiceResultId,
                    rightAnswerQuestionIds,
                    wrongAnswers
                };
            }
        }
    },
    mutations: {
        updatePracticeEveryday(state,change)
        {
            for(const key in change)
            {
                const value = change[key];
                state.practiceEveryday[key] = value;
            }
        },
        submitPracticeEverydayAnswer(state,{questionId,answer})
        {
            state.practiceEveryday.answer[questionId] = answer;
        },
        updatePracticeSelf(state,change)
        {
            for(const key in change)
            {
                const value = change[key];
                state.practiceSelf[key] = value;
            }
        },
        submitPracticeSelfAnswer(state,{questionId,answer})
        {
            state.practiceSelf.answer[questionId] = answer;
        }
    },
    actions: {
        async finishPracticeEveryday({state,getters,commit})
        {
            const {todayDone,practiceResultId,paper: {paperId}} = state.practiceEveryday.info;
            if(todayDone)
            {
                const result = await getPracticeResult(practiceResultId);
                const paper = await getPaperInfo(paperId);
                result.paper = paper;
                commit('updatePracticeEveryday',{
                    result: Object.freeze(result),
                });
            }
            const options = getters.getFinishPracticeOptions('practiceEveryday');
            return await finishPracticeEveryday(options);
        },
        async finishPracticeSelf({getters})
        {
            const options = getters.getFinishPracticeOptions('practiceSelf');
            return await finishPracticeSelf(options);
        }
    }
};