import { get, post, put } from "./config";

export const login = async ({username,password}) => {
    const url = '/app/user/erp-login';
    const data = {
        erpId: username,
        password
    };
    return await post(url,data);
};

export const editPassword = async ({password,newPassword0}) => {
    const url = '/app/user/password';
    const data = {
        oldPassword: password,
        newPassword: newPassword0
    };
    return await put(url,data,true);
};

export const getUserDetail = async () => {
    const url = '/app/user/me';
    return await get(url,true);
};

export const getUserList = async () => {
    const url = '/app/userNew/tbScoreList';
    return await get(url,true);
};

export const getMyScore = async (id) => {
    const url = '/app/userNew/getMyScore?id='+id;
    return await get(url,true);
};