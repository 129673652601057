export const isWeChat = () => {
    return /MicroMessenger/i.test(navigator.userAgent);
};

export const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

export const setTitle = str => {
    if(!isWeChat())document.title = str;
};

export const durationForamt = durationSec => {
    const c = durationSec;
    let hh = Math.floor(c/3600);
    let mm = Math.floor(c/60) - hh*60;
    let ss = c - hh*3600 - mm*60;
    if(hh < 10)hh = `0${hh}`;
    if(mm < 10)mm = `0${mm}`;
    if(ss < 10)ss = `0${ss}`;
    return `${hh}:${mm}:${ss}`;
};