import { finishExam } from '../server/exam';

export default {
    namespaced: true,
    state: {
        info: '',
        result: '',
        idx: -1,
        answer: {},
        beforeAnswer: '' //用于继续考试禁用上次答题
    },
    getters: {
        getFinishExamOptions(state)
        {
            return function()
            {
                const { result, answer } = state;
                const { examResultId, paper } = result;
                const qIds = Object.keys(answer);
                const rightAnswers = [];
                const wrongAnswers = [];
                const getQuestion = id => paper.questions.find(item => item.questionId == id);
                for(const qId of qIds)
                {
                    const userAnswer = answer[qId];
                    const question = getQuestion(qId);
                    if(userAnswer === question.rightAnswer)
                    {
                        rightAnswers.push({questionId: qId,answer: userAnswer});
                    }
                    else
                    {
                        wrongAnswers.push({questionId: qId,answer: userAnswer});
                    }
                }
                const rightAnswerQuestionIds = rightAnswers.map(item => item.questionId);
                return {
                    examResultId,
                    rightAnswerQuestionIds,
                    wrongAnswers
                };
            }
        }
    },
    mutations: {
        updateExam(state,change)
        {
            for(const key in change)
            {
                const value = change[key];
                state[key] = value;
            }
        },
        submitExamAnswer(state,{questionId,answer})
        {
            state.answer[questionId] = answer;
        }
    },
    actions: {
        async finishExam({getters})
        {
            const options = getters.getFinishExamOptions();
            return await finishExam(options);
        }
    }
};